import React from "react";
import NewEventForm from "./NewEventForm";
import NewEventContainer from "./NewEventContainer";

class NewEvent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.inlinePaymentBody) {
      $('#inline_payment_body').html(this.props.inlinePaymentBody);
      $('#pay_btn').addClass('theme_styles--button');
    }
  }

  render() {
    const isRescheduleForm = !!this.props.eventOriginalSchedule;

    const eventTypeLocation = this.props.eventTypeLocation || window.eventTypeLocation;
    const eventTypeLocationHidden = this.props.eventTypeLocationHidden || window.eventTypeLocationHidden;
    const useExternalLocation = this.props.useExternalLocation;
    const requestLocation = this.props.requestLocation || window.requestLocation;
    const isPaymentEnabled = this.props.isPaymentEnabled || window.isPaymentEnabled;
    const payment = this.props.payment || window.payment;
    const phoneFieldRequired = this.props.phoneFieldRequired || window.phoneFieldRequired;
    const isClientCanAddParticipants = this.props.isClientCanAddParticipants || window.isClientCanAddParticipants;
    const maxLimitOfParticipants = this.props.maxLimitOfParticipants || window.maxLimitOfParticipants;

    return (
      <section className="wrapper event-block--details-form">
        <div className="event-block--step event-block--step-form v1">
          <NewEventContainer
            isRescheduleForm={isRescheduleForm}
            eventTypeLocation={eventTypeLocation}
            eventTypeLocationHidden={eventTypeLocationHidden}
            useExternalLocation={useExternalLocation}
            requestLocation={requestLocation}
            isPaymentEnabled={isPaymentEnabled}
            payment={payment}
            selectedTime={this.props.selectedTime}
            selectedTimes={this.props.selectedTimes}
            selectedTimezone={this.props.selectedTimezone}
            eventOriginalSchedule={this.props.eventOriginalSchedule}
            selectedDayDate={this.props.selectedDayDate}
            eventTypeColor={this.props.eventTypeColor}
            eventTypeName={this.props.eventTypeName}
            isEventConfirmed={this.props.isEventConfirmed}
            newEvent={this.props.newEvent}
            isLoading={this.props.isLoading}
            onRescheduleSubmit={this.props.onRescheduleSubmit}
            onSelectMultipleTime={this.props.onSelectMultipleTime}
            hostName={this.props.hostName}
          >
            {
              this.props.inlinePaymentBody
                  ? <div id={"inline_payment_body"} className={'custom-form'}></div>
                  : <NewEventForm
                      onSubmit={this.props.onSubmit}
                      eventTypeId={this.props.eventTypeId}
                      newEvent={this.props.newEvent}
                      onChange={this.props.onChange}
                      setError={this.props.setError}
                      errors={this.props.errors}
                      isLoading={this.props.isLoading}
                      eventTypeShowPhone={this.props.eventTypeShowPhone}
                      questions={this.props.questions}
                      cancellationPolicy={this.props.cancellationPolicy}
                      simplypayPaymentSystems={this.props.simplypayPaymentSystems}
                      eventTypeRequestLocationTypes={this.props.eventTypeRequestLocationTypes}
                      requestLocation={this.props.requestLocation}
                      eventTypeLocationQuestion={this.props.eventTypeLocationQuestion}
                      eventTypeLocation={eventTypeLocation}
                      phoneFieldRequired={phoneFieldRequired}
                      isClientCanAddParticipants={isClientCanAddParticipants}
                      maxLimitOfParticipants={maxLimitOfParticipants}
                  />
            }
          </NewEventContainer>
        </div>
      </section>
    );
  }
}

export default NewEvent;